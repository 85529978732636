/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer1 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "pluryal.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.nucleofill}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>Polinukleotydy</Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="polinukleotydy pluryal biostymulator tkankowy ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Czym są biostymulatory tkankowe?</Subtitle>
            <Paragraph>
              Biostymulator tkankowy to substancja używana w medycynie
              estetycznej i regeneracyjnej, której celem jest stymulowanie
              naturalnych procesów regeneracyjnych w celu poprawy wyglądu i
              kondycji skóry. Biostymulatory działają poprzez aktywację
              fibroblastów (komórek produkujących kolagen i elastynę), co
              przyczynia się do zwiększenia produkcji kolagenu, elastyny oraz
              poprawy elastyczności i jędrności, a także zagęszczenia skóry, a
              także jej ogólnej regeneracji.
            </Paragraph>
            <Paragraph>
              <b>Biostymulatory tkankowe</b>, które używamy w IDEALE to
              preparaty do iniekcji, które zawierają substancje aktywne, takie
              jak{' '}
              <a href="/oferta/kwas-polimlekowy-sculptra/" target="_blank">
                kwas polimlekowy
              </a>
              , hydroksyapatyt wapnia czy{' '}
              <a
                href="/oferta/biostymulator-tkankowy-profhilo/"
                target="_blank"
              >
                kwas hialuronowy
              </a>
              , które są wstrzykiwane bezpośrednio do skóry w celu pobudzenia
              produkcji kolagenu i poprawy stanu skóry. Po wykonaniu zalecanej
              serii biostymulacji skóry dochodzi do znacznej poprawy struktury
              skóry.
            </Paragraph>
            <Paragraph>
              Biostymulatory tkankowe są stosowane w różnych procedurach
              medycyny estetycznej, w tym w leczeniu objawów starzenia się
              skóry, redukcji blizn, leczeniu cellulitu oraz w wielu innych
              zastosowaniach mających na celu poprawę kondycji i wyglądu skóry.
              Wybór odpowiedniego biostymulatora zależy od indywidualnych
              potrzeb pacjenta, rodzaju problemu skórnego oraz zaleceń lekarza.
              Każdy zabieg w IDEALE jest poprzedzony <b>konsultacją lekarską</b>
              .
            </Paragraph>
            <Subtitle>Wskazania do zabiegu</Subtitle>
            <Paragraph noMarginBottom>
              Polinukleotydy służą do głębokiej odbudowy i biorestrukturyzacji
              skóry. Ten innowacyjny zabieg z wykorzystaniem biostymulatorów
              tkankowych działa na trzech poziomach:
            </Paragraph>
            <List>
              <ListItem>Liftingu (napięcie i zagęszczenie skóry)</ListItem>
              <ListItem>Antyoksydacji</ListItem>
              <ListItem>Nawilżenia</ListItem>
            </List>
            <Paragraph>
              To niepowtarzalne, potrójne działanie na poziomie komórkowym,
              które powoduje wyraźne i długotrwałe odmłodzenie skóry. Rysy
              twarzy zostają poprawione.
            </Paragraph>
            <Paragraph>
              Skóra zostanie wprowadzona w proces biostymulacji, dzięki czemu
              zacznie się regenerować, ulegnie zagęszczeniu i ujędrnieniu.
              Dodatkowo preparat ma działanie antyoksydacyjne i nawilżające.
            </Paragraph>
            <Paragraph>
              Efekt na skórze jest wyraźny ale naturalny, bez zmieniania rysów
              twarzy. Obecnie zabiegi z użyciem stymulatorów tkankowych to
              najmodniejsze zabiegi medycyny estetycznej dające doskonałe
              rezultaty u młodszej grupy pacjentek. Z pomocą biostymulatorów
              uzyskujemy efekt liftingu, poprawy owalu twarzy oraz likwidacji
              zmarszczek.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Opis zabiegu z biostymulatorem na bazie polinukleotydów
            </Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. Polega on na podaniu preparatu za pomocą
              cienkiej igły w precyzyjnie wyznaczone punkty na twarzy w
              odpowiednich warstwach skóry.
            </Paragraph>
            <Paragraph>
              Zabieg z stymulatorem tkankowym jakim są polinukleotydy wykonuje
              się w miejscu wcześniej znieczulonym przy pomocy specjalnego
              kremu.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Po jakim czasie i jak długo widoczne są efekty biostymulatorów
            </Subtitle>
            <Paragraph>
              Pierwsze efekty zabiegu biostymulatorami tkankowymi widoczne są
              już po miesiącu. W celu kompleksowego odmłodzenia skóry
              najczęściej wykonuje się serię 2-4 zabiegów na twarz, szyję lub
              dekolt, w odstępie 2-4 tygodni. Następnie raz na pół roku jeden
              zabieg przypominający.
            </Paragraph>
            <Paragraph>
              Efekt utrzymuje się 1-1,5 roku. Zdjęcia ukazują efekt zabiegu u
              konkretnego pacjenta. Efekt zabiegu z użyciem biostymulatorów
              jakim są polinukletydy może się różnić w zależności od
              indywidualnych cech pacjenta, liczby powtórzeń zabiegu, stosowania
              się pacjenta do zaleceń pozabiegowych oraz umiejętności i
              doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <SImageWrapper>
              <Image
                src={images.first.childImageSharp}
                alt="polinukleotydy pluryal biostymulator tkankowy polinukleotydy"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>
              Cena do zabiegu z wykorzystaniem biostymulatorów tkankowych
            </Subtitle>
            <Paragraph>
              Cena pojedyczego zabiegu to 950 zł pod oczy (Silk) i 1 050 zł na skórę twarzy (Densify). Przy pierwszej wizycie odbierz
              swoją kartę lojalnościową i otrzymaj -30% na czwarty zabieg medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania do zabiegu</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do IDEALE Medycyna Estetyczna Warszawa Wola, gdzie
              odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer1;

export const Head = () => <SEO tags={TAGS.nucleofill} />;
